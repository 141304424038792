<template>
	<div class="tcd-main">
		<div class="main" v-if="JSON.stringify(trChangeOrderDetail)!='{}'">
			<yj-progress-edit :statusList="statusList" :currentStatus="currentStatus"></yj-progress-edit>
			<type-order :dataDetail="trChangeOrderDetail" :marginTop="'0'" :title="'改签订单'" :orderType="11011"></type-order>
			<order-tg-info :dataDetail="trChangeOrderDetail" :marginTop="'20px'" :title="'原车次'" :orderType="11011"></order-tg-info>
			<passenger-info :dataDetail="trChangeOrderDetail" :marginTop="'20px'" :title="'乘车人信息'" :orderType="11011"></passenger-info>
			<change-info :dataDetail="trChangeOrderDetail"  :newPrice="newPrice" :marginTop="'20px'" :title="'改签信息'"></change-info>
			<el-card v-if="trRefundList.length>0" class="marTop20">
				<h1 class='textLeft'>退票信息</h1>
				<div v-for="(item, index) in trRefundList" :key="index" class="marTop20 bg-c" >
					<div class="flex between">
						<span class="btn">{{item.refundStatusDesc}}</span>
						<span style="padding-right:10px;" class="line-h33">应退合计 {{item.cuRefundAmount}}</span>
					</div>
					<div class="flex between" style="margin:10px 0;padding:10px 0;">
						<div style="width: 15%;" class="line-h35 borderR">{{item.trainCode}} ({{item.seatTypeName}})</div>
						<div style="width: 15%;"  class="line-h35 borderR">
							{{item.fromDate}} {{item.fromWeek}}
						</div>
						<div class="flex between borderR" style="width: 30%;">
							<div style="width: 30%;">
								<p>{{item.fromTime}}</p>
								<p>{{item.fromStationName}}</p>
							</div>
							<div style="width: 40%;">
								<p class="xclass  iconfont yj-icon-jiantou1"></p>
							</div>
							<div style="width: 30%;">
								<p>{{item.toTime}}</p>
								<p>{{item.toStationName}}</p>
							</div>
						</div>
						<div style="width: 30%;" class="line-h35 borderR">
							<p >乘车人 {{item.passengerName}} ({{item.idCode}})</p>
						</div>
						<div style="width: 10%;" class="line-h35">
							<p class="pointer colorBlue"  @click="goToTrRefundDetail(item.id)">查看订单详情</p>
						</div>
					</div>
				</div>
			</el-card>
			<yj-contact-info :marginTop="'20px'" ></yj-contact-info>
			<el-card v-if="trChangeOrderDetail.appId && trChangeOrderDetail.vipStatus==0" class="cardText">
				<div class="title">审批规则详情</div>
				<div class="trd-jd">
					<yj-approval-rule-detail :detail="detail" :appId="detail.appId" @gznrList="getGznrList"></yj-approval-rule-detail>	
				</div>	
				<div v-if="showSend" style="margin-top: 20px;">
					
				</div>
			</el-card>
			<el-card v-if="trChangeOrderDetail.appId && trChangeOrderDetail.vipStatus>0 && trChangeOrderDetail.vipStatus<4" class="cardText">
				<div class="title">审批进度<span style="font-size: 15px;color: #808080;padding-left: 20px;">({{spjd}})</span></div>
				<div class="trd-jd">
					<yj-approval-progress :orderId="query.orderId" :orderType="query.orderType" :resetspjd="resetspjd" @showProgress="showProgress"></yj-approval-progress>
				</div>			
			</el-card>
			<!-- <yj-need-my-approval v-if="isSpStatus && query.workItemId" :workItemId="query.workItemId" :orderId="query.orderId" @spSuccess="resetDetail"></yj-need-my-approval> -->
			


			<yj-approval-cao-zuo  
				:appType="query.appType" 
				:workItemId="query.workItemId"
				:orderType="query.orderType" 
				:orderId="query.orderId" 
				@closePage="resetDetail">
					<template slot="orderFooterSlot">
						<div class="btn-footer">
							<el-button  class="button" v-if="trChangeOrderDetail.showSendCkButton==1"  @click="sendApp">送 审</el-button>
							<el-button class="button" v-if="trChangeOrderDetail.showCancelButton==1" @click="orderCaoZuo(1,trChangeOrderDetail)">取 消</el-button>
							<el-button class="button" v-if="trChangeOrderDetail.showPayButton==1" @click="orderCaoZuo(3,trChangeOrderDetail)">支 付</el-button>
							<el-button class="button" v-if='trChangeOrderDetail.showConfirmButton==1 && !(trChangeOrderDetail.failureCode == "602020" || trChangeOrderDetail.failureCode == "600108" || trChangeOrderDetail.failureCode == "600109" || trChangeOrderDetail.failureCode == "602021" || trChangeOrderDetail.failureCode == "602001" || trChangeOrderDetail.failureCode == "602009")' @click="orderCaoZuo(5,trChangeOrderDetail)">确定出票</el-button>
							<!-- v-if='(trChangeOrderDetail.failureCode == "602020" || trChangeOrderDetail.failureCode == "600108" || trChangeOrderDetail.failureCode == "600109")' -->
							<el-button class="button" @click="checkLogin">12306核验</el-button>
							<el-button class="button" v-if="trChangeOrderDetail.isShowCancelApprove==1" @click="orderCaoZuo(16,trChangeOrderDetail)">撤回</el-button>
						</div>
					</template>	
			</yj-approval-cao-zuo>
		</div>
		<!-- <div v-if='trChangeOrderDetail.failureCode == "602020" || trChangeOrderDetail.failureCode == "600108" || trChangeOrderDetail.failureCode == "600109"'> -->
			<train-login
				:showTrLogin="false" 
				:orderVisible="visible"
				:checkVisible="checkVisible"
				:cusTomerSendSmsProp="cusTomerSendSmsProp"
				:isQueryOrderAccount="isQueryOrderAccount"
				:orderInfo="orderInfo"
				@needSuccess="needSuccess" 
				@loginSuccess="loginSuccess" 
				@cancelLogin="cancelLogin"
				class="orderTrLogin"
			></train-login>
		<!-- </div> -->
		
	</div>
</template>

<script>
	import caozuoMixin from '@/mixin/models/caozuoMixin.js';

	import TrainLogin from '@/views/tr/trComponents/trEdit/trainYdEdit/TrainLogin';

	import ChangeInfo from './trTGComponent/ChangeInfo';
	
	import OrderTGInfo from './trTGComponent/OrderTGInfo';
	
	import PassengerInfo from './trTGComponent/PassengerInfo';
	

import TypeOrder from './trTGComponent/TypeOrder';
	
	export default{
		name: 'TrainChangeDetail',
		mixins:[caozuoMixin.commonConfig],
		data() {
			return {
				trChangeOrderDetail : {},
				trRefundList: [], //改签退票列表
				newPrice: '', // 火车票单价
				isSpStatus: false,
				spjd:1, //审批进度
				showSend: false,
				// query:{},
				detail:{
					flowState: 3, // 当前人的审批状态 默认审批中  0未开始 1通过 2拒绝 3审批中
					currentLevel: 0,  //需要审批人 当前层级
				},
				resetspjd: false,
				
				statusList: [], //进度条
				currentStatus: 0, // 进度条状态
				visible: false, //是否显示 12036 登录页面
				isQueryOrderAccount: true,// 是否查询订单关联的12306账号
				cusTomerSendSmsProp: true, // 走true-正常核验还是false-主动触发的核验
				checkVisible: false, // 是否显示核验页面
				orderInfo: { // 选择的订单信息
					// orderType: '',
					// orderId: '',
				} 
			}
		},
		components:{
			'type-order': TypeOrder,
			'order-tg-info': OrderTGInfo,
			'passenger-info': PassengerInfo,
			'change-info': ChangeInfo,
			'train-login':TrainLogin,
		},
		computed:{
			query(){
				return this.$route.query || {}
			}
		},
		mounted() {
			let uesrInfo = this.$common.getUserInfo() || {};
			this.empId = uesrInfo.empInfo.empId;
			// this.query = this.$route.query || {};
			this.getTrainChangeDetail();
			this.getFindTrRefundOrderByIds();
		},
		methods: {
			// 获取火车改签票详情
			getTrainChangeDetail(){
				let obj = {
					changeId: this.query.orderId
				}
				this.$conn.getConn('trChange.trChangeOrderDetail',{jsfile:false})(obj,(res)=>{
					let data = res.data || {}
					this.trChangeOrderDetail = data;
					this.gressBiao(data);
					if(this.trChangeOrderDetail.appId){
						this.detail.appId = this.trChangeOrderDetail.appId
					}
					this.showSend = (data.showSendCkButton == 1);
					this.newPrice = this.trChangeOrderDetail.psgList[0].newTicketPrice;
				},(err)=>{
					this.$alert(err.tips,'温馨提示',{
						type: 'error' 
					})
				})
			},
			// 进度条处理
			gressBiao(data){
				if(data.changeAmount<0){
					if(data.vipStatus != 4){
						this.statusList = [
							{status: '1',name: '申请已提交',},
							{status: '2',name: '送审',},
							{status: '3',name: '已订座',},
							{status: '4',name: '改签完成',},
							{status: '5',name: '已退款',},
						];
						if(data.changeStatus == 1){
							this.currentStatus = 1;
						}else if(data.changeStatus == 2 || data.changeStatus == 3){
							this.currentStatus = 2;
						}else if(data.changeStatus == 4){
							this.currentStatus = 2;
						}else if(data.changeStatus == 6 || data.changeStatus == 0){
							this.currentStatus = 4;
						}else if(data.changeStatus == 6 || data.changeStatus == 1){
							this.currentStatus = 5;
						}else if(data.changeStatus == 7 || data.changeStatus == 8 || data.changeStatus == 9){
							this.currentStatus = 0;
						}
					}else{
						this.statusList = [
							{status: '1',name: '申请已提交',},
							{status: '2',name: '已订座',},
							{status: '3',name: '改签完成',},
							{status: '4',name: '已退款',},
						];
						if(data.changeStatus == 1 || data.changeStatus == 2 || data.changeStatus == 3){
							this.currentStatus = 1;
						}else if(data.changeStatus == 4){
							this.currentStatus = 2;
						}else if(data.changeStatus == 6 || data.changeStatus == 0){
							this.currentStatus = 3;
						}else if(data.changeStatus == 6 || data.changeStatus == 1){
							this.currentStatus = 4;
						}else if(data.changeStatus == 7 || data.changeStatus == 8 || data.changeStatus == 9){
							this.currentStatus = 0;
						}
					}					
				}else{
					if(data.vipStatus != 4){
						this.statusList = [
							{status: '1',name: '申请已提交',},
							{status: '2',name: '送审',},
							{status: '3',name: '已订座',},
							{status: '4',name: '已支付',},
							{status: '5',name: '改签完成',},
						];
						if(data.changeStatus == 1){
							this.currentStatus = 1;
						}else if(data.changeStatus == 2 || data.changeStatus == 3){
							this.currentStatus = 2;
						}else if(data.changeStatus == 4){
							if(data.payStatus == 1){
								this.currentStatus = 4;
							}else{
								this.currentStatus = 3;
							}
						}else if(data.changeStatus == 6){
							this.currentStatus = 5;
						}else if(data.changeStatus == 7 || data.changeStatus == 8 || data.changeStatus == 9){
							this.currentStatus = 0;
						}
					}else{
						this.statusList = [
							{status: '1',name: '申请已提交',},
							{status: '2',name: '已订座',},
							{status: '3',name: '已支付',},
							{status: '4',name: '改签完成',},
						];
						if(data.changeStatus == 1 || data.changeStatus == 2 || data.changeStatus == 3){
							this.currentStatus = 1;
						}else if(data.changeStatus == 4){
							if(data.payStatus == 1){
								this.currentStatus = 3;
							}else{
								this.currentStatus = 2;
							}
						}else if(data.changeStatus == 6){
							this.currentStatus = 4;
						}else if(data.changeStatus == 7 || data.changeStatus == 8 || data.changeStatus == 9){
							this.currentStatus = 0;
						}
					}
				}
			},
			// 获取退票信息
			getFindTrRefundOrderByIds(){
				let obj = {
					changeId: this.query.orderId
				}
				this.$conn.getConn('trRefund.findTrRefundOrderByIds',{jsfile:false})(obj,(res)=>{
					let data = res.data || []
					this.trRefundList = data;
				},(err)=>{
					this.$alert(err.tips,'温馨提示',{
						type: 'error' 
					})
				})
			},
			// 去退票详情页
			goToTrRefundDetail(id){
				let routeUrl = this.$router.resolve({path: "trainRefundDetail",query:{orderId:id}});
				window.open(routeUrl.href,'_blank')
			},
			// 获取操作
			orderCaoZuo(type,detail){
				if(type==1){
					this.cancelChange(detail.changeId);
				}else if(type==3){
					this.goToPayment(detail);
				}else if(type==5){
					this.getChangeTicket(detail.changeId);
				}else if(type == 16){
					this.goToCancelApprove({
						orderId: this.query.orderId,
						orderType: 11011,
					}, (res) => {

						this.getTrainChangeDetail();
					}, (err) => {

					})
				}
			},
			// 审批进度
			showProgress(info,currentSpjd,status){
				this.spjd = info.spjd; //当前审批进度（1/2）
				// 展示详细所用信息
				this.detail.currentLevel = currentSpjd; //当前到谁审批
				this.detail.flowState = status;  // 当前审人状态
				this.resetspjd = false;
				this.getCurrentSprLevel();
			},
			// 获取当前审批人层级列表
			getCurrentSprLevel(){
				
				let params = {
					spgzId: this.detail.appId
				}
				this.$conn.getConn('commu.appRulesDetail')(params,(res)=>{
					let data =  res.data || {};
					let applyList = data.gznr || [];
					this.getGznrList(applyList)
				},(err)=>{
					
				})
			},
			// 火车票支付时间
			overTime(detail) {
				let diffTime = 0; //对比分钟
				let time = 0; //最终返回分钟
				if (detail.latePaymentTime) {
					// 计算对比最晚支付时间
					diffTime = this.$common.getDiffMinute(detail.applyDate, detail.latePaymentTime);
					let sTime = diffTime.toFixed(2).toString().split(".");
					if(sTime.length==2){
						let second = sTime[1]-0>=10?((sTime[1]-0)*60/100).toFixed(0):((sTime[1]-0)*60/10).toFixed(0)
						time = sTime[0]+'.'+second;
					}else{
						time = Math.ceil(diffTime)
					}
				}
				return time;
			},
			// 去支付页
			goToPayment(detail){
				let time = this.overTime(detail);
				// 这里看下是传订单id orderId 还是改签单changeId
				var query = {
					orderId: detail.changeId,
					orderType: 11011,
					timeOut:time, 
				}
				var path = "/yjDetail/payment"
				this.$router.push({path:path,query:query})
			},
			// 取消改签
			cancelChange(changeId){
				let params = {
					changeId: changeId
				}
				var url = "trChange.cancelTrChangeOrder";
				this.$confirm('确定取消改签订单!', '提示', {
				        confirmButtonText: '确定',
				        cancelButtonText: '取消',
						closeOnClickModal: false,
				        type: 'warning'
				    }).then(() => {
						this.$conn.getConn(url)(params,(res)=>{
							this.$alert('取消成功！','提示',{ 
								type:'success',
								confirmButtonText: '确定',
								callback: action => {
									this.getTrainChangeDetail();
									this.getFindTrRefundOrderByIds();
								}
							})
							
						},(err)=>{
							this.$alert(err.tips,'提示',{
								type:'error',
							})
						})
				        
				    }).catch(() => {
				        this.$message({
				        	type: 'info',
				        	message: '取消关闭',
				        });     
				    });  
				
			},
			// 获取审批人列表
			getGznrList(list){
			
				if(list.length>0){
					// 遍历审批人
					for(var i=0; i<list.length; i++){
						// 需要审批的当前层级 与 列表中层级相等表示 轮到当前人审批
						if(list[i].level == this.detail.currentLevel){
							// 判断登录人empid 与 层级中id 是否相等， 相等则可以审批
							for(var j=0; j<list[i].empList.length;j++){
								if(list[i].empList[j].empId == this.empId && this.detail.flowState==3){
									this.isSpStatus = true;
								}
								// else{
								// 	this.isSpStatus = false;
								// }
							}
						}
					}
				}
				
			},
			// 送审
			sendApp(){
				var queryParams = {};
					queryParams = {
						orderId: this.query.orderId,
						orderType: 11011,
						spgzId: this.detail.appId,
					};
				this.$confirm('您确定要送审吗?', '', {
				    confirmButtonText: '确定',
				    cancelButtonText: '取消',
				    type: 'warning'
				    }).then(() => {
				    	this.$conn.getConn('commu.sendApp')(queryParams,(res)=>{
							this.$message({
							    type: 'success',
							    message: '送审成功'
							}); 
							this.getTrainChangeDetail();
							window.close();
						},(err)=>{
							this.$message({
							    type: 'error',
							    message: err.tips
							});   
						})
				    }).catch(() => {
				      this.$message({
				        type: 'info',
				        message: '关闭取消'
				    });              
				});
			},
			// 审批完 重置页面
			resetDetail(){
				
				
				// 审批成功 通知父页面 刷新
				window.opener.needMyApprovalRefresh(1);
				
				this.$router.replace({
					path: this.$dict.detailConfig(11011),
					query: {
						...this.$route.query,
						appType:2  // 已审批     0 订单详情 1.待审批 2.已审批 3. 审批过期 
	
					}
				})
				// this.isSpStatus = false;
				this.resetspjd = true;
				this.getTrainChangeDetail();
			},
			// 确认改签出票
			getChangeTicket(changeId){
				let params = {
					changeId: changeId
				}
				var url = "trChange.confirmTrChangeOrder";
				this.$conn.getConn(url)(params,(res)=>{
					this.$alert('','改签出票成功！',{ 
						type:'success',
						confirmButtonText: '确定',
						callback: action => {
							this.getTrainChangeDetail();
							this.getFindTrRefundOrderByIds();
						}
					})
					
				},(err)=>{
					this.$alert(err.tips,'取消失败！',{
						type:'error',
					})
				})
			},
			
			// 12306核验
			checkLogin(){
				this.orderInfo = {
					orderId: this.trChangeOrderDetail.changeId,
					orderType: 11011,
				};				
				setTimeout(()=>{
					if (this.trChangeOrderDetail.failureCode == '602020' || this.trChangeOrderDetail.failureCode == '600109') {
						this.checkVisible = true;
						this.cusTomerSendSmsProp = this.trChangeOrderDetail.failureCode == '602020'? false: true;
					} else {
						this.visible = true;
					}
				},200)	
			},
			//登录成功
			loginSuccess(){
				this.visible = false;
				this.checkVisible = false;
			},
			// 核验成功 重置订单详情
			needSuccess(){
				this.getTrainChangeDetail();
			},
			cancelLogin(){
				this.loginSuccess();
			}
		}
	}
</script>

<style scoped lang="scss">
	.tcd-main{
		background-color: #EEF1F6;
		font-family: '微软雅黑';
		.main{
			width: 1200px;
			margin: 0 auto;
			padding-top: 20px;
		}
		.marTop20{
			margin-top: 20px;
		}
		.textRight{
			text-align: right;
		}
		.btn-footer{
			padding: 30px 0;
			.button{
				font-size: 15px;
				width: 150px;
				height: 50px;
				
			}
		}
		.orderTrLogin{
			/deep/ .el-input__inner{
				height: 35px;
				background: #FFFFFF;
				border: 1px solid #CCCCCC!important;
				border-radius: 0;
			}
		}	
		.cardText{
			margin-top: 20px;
			/deep/ .el-card__body{
				padding: 30px;
			}
			.title{
				font-size: 24px;
				font-weight: 600;
				color: #424242;
				line-height: 24px;
				text-align: left;
			}
			.trd-jd{
				margin-top: 30px;
				border: 1px solid #e6e6e6;
				width: 100%;
				padding: 20px;
			}
			.spmain{
				margin-top: 38px;
			}
			.spselcet{
				text-align: left;
				padding-bottom: 10px;
			}
		}
		.btn{
			padding: 4px 12px;
			background-color: #006BB9;
			color: #ffffff;
			font-size: 15px;
			border-radius: 2px;
			margin: 5px 0 0 10px;
		}
		.flex{
			display: flex;
		}
		.h35{
			height: 35px;
		}
		.line-h33{
			line-height: 33px;
		}
		.line-h35{
			line-height: 35px;
		}
		.borderR{
			border-right: 1px solid #D1DBE7;
		}
		.between{
			justify-content: space-between;
		}
		.textLeft{
			text-align: left;
		}
		.border{
			border: 1px solid #D1DBE7;
			box-sizing:border-box;
		}
		.marTop10{
			margin-top: 10px;
		}
		.inlineB{
			display: inline-block;
		}
		.bg-c{
			background-color: #F3F5F8;
		}
		.xclass{
			color: #D1DBE7;
			margin-top: 10px;
			font-size: 20px;
		}
		.colorBlue{
			color: #006BB9;
		}
		.pointer{
			cursor: pointer;
		}
		/deep/ .el-card__body{
			padding: 30px;
		}
	}
</style>
