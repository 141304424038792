<template>
	<el-card class="otgi-main" :style="{'margin-top':marginTop,}">
		<h1 class="textLeft otgi-title">{{title}}</h1>
		<div class="flex setContent">
			<div class="borderR setText" v-if="orderType==11010">{{dataDetail.trainCode}} ({{dataDetail.seatTypeName}})</div>
			<div class="borderR setText" v-if="orderType==11011">{{dataDetail.oldTrainCode}} ({{dataDetail.oldSeatTypeName}})</div>
			<div class="borderR flex" style="width: 530px;" v-if="orderType==11010">
				<div style="width: 189px;" >
					<p class="time">{{dataDetail.fromTime}}</p>
					<p class="city">{{dataDetail.fromStationName}}</p>
				</div>
				<div style="width: 150px;" >
					<p class="setTime">{{dataDetail.travelTimeStr}}</p>
					<p class="otgi-jt iconfont yj-icon-jiantou1"></p>
				</div>
				<div style="width: 189px;">
					<p class="time">{{dataDetail.toTime}}</p>
					<p class="city">{{dataDetail.toStationName}}</p>
				</div>
			</div>
			<div class="borderR flex" style="width: 530px;" v-if="orderType==11011">
				<div style="width: 189px;" >
					<p class="time">{{dataDetail.oldFromTime}}</p>
					<p class="city">{{dataDetail.oldFromStationName}}</p>
				</div>
				<div style="width: 150px;" >
					<p class="setTime">{{dataDetail.oldTravelTimeStr}}</p>
					<p class="otgi-jt iconfont yj-icon-jiantou1"></p>
				</div>
				<div style="width: 189px;">
					<p class="time">{{dataDetail.oldToTime}}</p>
					<p class="city">{{dataDetail.oldToStationName}}</p>
				</div>
			</div>
			<div class="borderR line-h40" style="width: 240px;">
				<span v-if="orderType==11010">{{dataDetail.fromDate}} {{dataDetail.fromWeek}}</span>
				<span v-else>{{dataDetail.oldFromDate}} {{dataDetail.oldFromWeek}}</span>
			</div>
			<div style="width: 160px;" @click="showGZ" class="line-h40 pointer color">退改签规则</div>
		</div>
		<el-dialog
			title="退改签规则"
			:visible.sync="visible"
			:lock-scroll="false"
			>
			<div class="flex between textLeft" v-if="orderType==11010?true:false">
				<div style="width: 92px;" class="flex alignItem font16">退票规则</div>
				<div style="width: 608px;"  class="textline">
					<p>(1)没有换取纸质车票且不晚于开车前30分钟的，可以在线申请办理退票。</p>
					<p>(2)已经换取纸质车票或者在开车前30分钟之内的，请携带购票时所使用的乘车人有效身份证件原件到车站售票窗口办理；</p>
					<p>(3)开车前15天（不含）以上退票的，不收取退票费；票面乘车站开车时间前48小时以上的按票价5%计，24小时以上、不足48小时的按票价10%计，不足24小时的按票价20%计。</p>
					<p>(4)开车前48小时～15天期间内，改签或变更到站至距开车15天以上的其他列车，又在距开车15天前退票的，仍核收5%的退票费。</p>
					<p>(5)根据退票手续费率计算的手续费以0.5元为单位，尾数小于0.25元的舍去、0.25元以上且小于0.75元的计为0.5元、0.75元以上的进为1元。退票费最低按2元计收。</p>
					<p>(6)接口订单，必须一张一张退。如果多张一起退票，系统会自动拆分生成多个退票单；</p>
				</div>
			</div>
			<div class="flex between textLeft marTop20" v-if="orderType==11011?true:false">
				<div style="width: 92px;" class="flex alignItem font16">改签规则</div>
				<div style="width: 608px;" class="textline">
					<p>(1)改签只能对未换取纸质车票，且离发车时间大于35分钟的车票进行改签；</p>
					<p>(2)可批量改签，批量改签时，选择的新票座位必须一致，并且不能是卧铺；</p>
					<p>(3)一张车票只能办理一次改签，改签后若要退票请到车站办理；</p>
					<p>(4)开车前48h以上，可改签预售期内的车次；开车前48h以内，可改签从办理改签之时到票面日期当日24：00之间的任意车次；不办理票面日期次日及以后的改签。</p>
					<p>(5)新票价大于原票价，补收差额；新票价小于原票价，退还差额，对差额部分核收退票费并执行现行退票费标准；</p>
				</div>
			</div>
		  <span slot="footer" class="dialog-footer">
		    <el-button type="primary" @click="visible = false">关 闭</el-button>
		  </span>
		</el-dialog>
	</el-card>
</template>

<script>
	export default{
		name: 'OrderTGInfo',
		props: {
			dataDetail:{
				type: Object,
				default() {
					return {}
				}
			},
			marginTop:{
				type: String,
				default: '20px'
			},
			title:{
				type: String,
				default: ''
			},
			orderType: {
				type: Number,
				default: 11010,
			}
		},
		data() {
			return {
				visible: false,
			}
		},
		methods: {
			// 显示退改签规则
			showGZ(){
				if(this.visible){
					this.visible = false;
				}else{
					this.visible = true;
				}
			}
		}
	}
</script>

<style scoped lang="scss">
	.el-card.is-always-shadow{
		box-shadow: 1px 2px 2px 0px rgba(13, 2, 0, 0.02);
		border-radius: 5px 
	}
	.otgi-main{
		font-family: '微软雅黑';
		.otgi-title{
			font-size: 24px;
			font-weight: 600;
			color: #424242;
			line-height: 24px;
		}
		.setContent{
			height: 80px;
			background: #F3F5F8;
			border-radius: 5px;
			margin-top: 27px;
			box-sizing: border-box;
			padding: 20px 0;
		}
		.setText{
			width: 200px;
			font-size: 18px;
			font-weight: bold;
			color: #223344;
			line-height: 40px;
		}
		.time{
			font-size: 18px;
			font-weight: bold;
			color: #223344;
			line-height: 15px;
		}
		.setTime{
			font-size: 12px;
			font-weight: 400;
			color: #223344;
			line-height: 15px;
		}
		.city{
			font-size: 14px;
			font-weight: 400;
			color: #223344;
			line-height: 15px;
			padding-top: 8px;
		}
		/deep/ .el-card__body{
			padding: 30px;
		}
		.flex{
			display: flex;
		}
		/deep/ .el-dialog{
			width: 700px;
		}
		.between{
			justify-content: space-between;
		}
		.alignItem{
			align-items: center;
		}
		.textline{
			p{
				line-height: 24px;
			}
		}
		.font16{
			font-size: 16px;
		}
		.bold{
			font-weight: bold;
		}
		.textLeft{
			text-align: left;
		}
		.color{
			color: #006BB9;
		}
		.line-h40{
			line-height: 40px;
		}
		.borderR{
			border-right: 1px solid #D1DBE7 ;
		}
		.pointer{
			cursor: pointer;
		}
		.otgi-jt{
			
			color: #D1DBE7;
			// width: 100%;
			// margin-top: 15px;
			font-size: 20px;
			
		}
	}
</style>
