<template>
	<div class='td-main'>
		<yj-progress-edit :statusList="statusList" :currentStatus="currentStatus"></yj-progress-edit>
		<el-card class='textLeft marTop20'>
			<div class="marBtm10 flex between">
				<p>
					<span class="label-span">订单号：</span>
					{{dataDetail.orderNo?dataDetail.orderNo:''}}</p>
				<p :style="getTextColor()">
					<span v-if="query.overdueFlag==1" style="color:red">行程过期</span>
					<span v-else>
						{{dataDetail.orderStatusDesc}}
						{{dataDetail.orderStatus==17?': '+dataDetail.errMsg:''}}</span>
				</p>
			</div>
			<div class="marBtm10 border" style="padding:20px 0 20px 40px;"
				v-if="dataDetail.appId && dataDetail.vipStatus==0">
				<yj-approval-rule-detail :detail="detail" :appId="dataDetail.appId" @gznrList="getGznrList">
				</yj-approval-rule-detail>
			</div>
			<div class="marBtm10 border" style="padding:20px 0 20px 40px;"
				v-if="dataDetail.appId && dataDetail.vipStatus>0 && dataDetail.vipStatus<4">
				<yj-approval-progress :orderId="orderId" :orderType="orderType" :resetspjd="resetspjd"
					@showProgress="showProgress"></yj-approval-progress>
			</div>
			<div class="marBtm10 border flex h80">
				<div class="left-main-width text-center bg line-h78 borderRight">
					基本信息
				</div>
				<div style="width:40px;"></div>
				<div class="right-main-width flex line-h78">
					<div class="bfb-w25">
						<span class="label-span">预订人：</span>
						<span>{{dataDetail.bookerName}}</span>
					</div>
					<div class="bfb-w25">
						<span class="label-span">服务商：</span>
						<span>{{dataDetail.supplierName }}</span>
					</div>
					<div class="bfb-w25">
						<span class="label-span">预订时间：</span>
						<span>{{dataDetail.bookDate}}</span>
					</div>
					<div class="bfb-w25">
						<span class="label-span">支付方式：</span>
						<span>{{dataDetail.payMothedName}}</span>
					</div>
				</div>
			</div>
			<div class="marBtm10 border flex h80">
				<div class="left-main-width flex flexCenter bg borderRight">
					车次信息
				</div>
				<div style="width:996px;">
					<div class="flex">
						<div style="width:80%;padding:40px 0;" class="flex">
							<div style="width: 33%;text-align: center;">
								<p class="bold">{{dataDetail.fromTime}}</p>
								<p class="h50 line-h50">{{dataDetail.fromStationName}}</p>
								<p>{{dataDetail.fromDate}} {{dataDetail.fromWeek}}</p>
							</div>
							<div style="width: 33%;">
								<div class="relative">
									<p class="absolute iconfont yj-icon-jiantou1 pCenter"></p>
									<p class="absolute ls-btm" style="text-align: center;width:100%;">
										{{dataDetail.travelTimeStr?dataDetail.travelTimeStr:''}}
									</p>
								</div>

							</div>
							<div style="width: 33%;text-align: center;">
								<p>
									<span class="bold">{{dataDetail.toTime}}</span>
									<span class="orange font12 align_top" v-if="days > 0">+{{days}}</span>
								</p>
								<p class="h50 line-h50">{{dataDetail.toStationName}}</p>
								<p>{{dataDetail.toDate}} {{dataDetail.week}}</p>
							</div>
						</div>
						<div style="width:20%;">
							<refund-change-rule :styleColor="styleColor"></refund-change-rule>
						</div>

					</div>
					<div class="flex line-h40 borderTop" style="padding-left: 40px;" v-for="item in dataDetail.psgList"
						:key="item.ticketCode">
						<div class="bfb-w25">
							<span class="label-span">车次号：</span>
							<span>{{dataDetail.trainCode}}</span>
						</div>
						<div class="bfb-w25">
							<span class="label-span">坐席：</span>
							<span>{{dataDetail.seatTypeName}}</span>
						</div>
						<div class="bfb-w25">
							<span class="label-span">票号：</span>
							<span>{{item.ticketCode}}</span>
						</div>
						<div class="bfb-w25">
							<span class="label-span">取票号：</span>
							<span>{{dataDetail.takeTicketCode}}</span>
						</div>
					</div>
				</div>
			</div>
			<div class="marBtm10 border flex h80">
				<div class="left-main-width flex flexCenter bg borderRight">
					乘车人
				</div>
				<div style="width:40px;"></div>
				<div class="right-main-width" :class="{'padTB30':isPadTB==true,'line-h78':isPadTB==false}">
					<span v-for="(item, index) in dataDetail.psgList" :key="index" class="flex"
						:class="{'line-h24':(isPadTB==true)}">
						<div class="bfb-w25">
							<span class="label-span">乘车人：</span>
							<span>{{item.passengerName}}</span>
						</div>
						<div class="bfb-w25">
							<span class="label-span">身份证：</span>
							<span>{{item.idCode}}</span>
						</div>
						<div class="bfb-w25">
							<span class="label-span">座位号：</span>
							<span>{{item.seatCode}}</span>
						</div>
						<div class="bfb-w25">
							<span class="label-span">成本中心：</span>
							<span>{{item.costCenterName}}</span>
						</div>
						
					</span>
				</div>
			</div>

			<div class="marBtm10 border flex h80" v-if="JSON.stringify(bxInfo)!='{}' && bxInfo.bxcpList.length>0">
				<div class="left-main-width flex flexCenter bg borderRight">
					保险信息
				</div>
				<div style="width:996px;padding:10px;">
					<el-table border :data="bxInfo.bxcpList" style="width: 100%">
						<el-table-column type="expand">
							<template slot-scope="props">
								<div v-for="(item, index) in props.row.bbrList" :key="index">
									{{item.bbr}}
									身份证 ({{item.bIdNo}})
									{{item.tbztDesc}}
								</div>
							</template>
						</el-table-column>
						<el-table-column label="保险产品" prop="bxcpmc">
						</el-table-column>
						<el-table-column label="保险险种" prop="xzmc">
						</el-table-column>
						<el-table-column label="产品说明" prop="">
							<template slot-scope="props">
								<el-popover placement="bottom" width="600" trigger="hover" :content="props.row.bxcpmc">
									<slot name="content">
										<div>
											<span
												style="width:20%;display: inline-block;line-height: auto;">保额说明：</span><span
												style="width:80%;display: inline-block;"></span>
										</div>
										<div>
											<span
												style="width:20%;display: inline-block;line-height: auto;">销售说明：</span><span
												style="width:80%;display: inline-block;"></span>
										</div>
										<div>
											<span
												style="width:20%;display: inline-block;line-height: auto;">生效规则：</span><span
												style="width:80%;display: inline-block;"></span>
										</div>
									</slot>
									<el-button slot="reference" type="text">产品说明</el-button>
								</el-popover>
							</template>
						</el-table-column>
						<el-table-column label="销售价/份数" prop="salePrice">
							<template slot-scope="props">
								<div>
									{{props.row.salePrice}}元/人X{{props.row.bbrList.length}}
								</div>
							</template>
						</el-table-column>
						<el-table-column label="小计" prop="bxInfo.bxTotalPrice">
							<template slot-scope="props">
								<div>
									￥{{props.row.salePrice * props.row.bbrList.length}}
								</div>
							</template>
						</el-table-column>
					</el-table>
					<div style="text-align: right;line-height: 30px;">
						保险合计：<span style="color:#FFA558;">￥{{bxInfo.bxTotalPrice}}</span>
					</div>
				</div>
			</div>

			<div class="marBtm10 border flex h80" v-if="trRefundList.length>0">
				<div class="left-main-width flex flexCenter bg borderRight">
					退票信息
				</div>
				<div style="width:996px;">
					<div class="flex">
						<div style="width:80%;padding:40px 0;" class="flex">
							<div style="width: 33%;text-align: center;">
								<p class="bold">{{dataDetail.fromTime}}</p>
								<p class="h50 line-h50">{{dataDetail.fromStationName}}</p>
								<p>{{dataDetail.fromDate}} {{dataDetail.fromWeek}}</p>
							</div>
							<div style="width: 33%;">
								<div class="relative">
									<p class="absolute iconfont yj-icon-jiantou1 pCenter"></p>
									<p class="absolute ls-btm" style="text-align: center;width:100%;">
										{{dataDetail.travelTimeStr}}
									</p>
								</div>

							</div>
							<div style="width: 33%;text-align: center;">
								<p>
									<span class="bold">{{dataDetail.toTime}}</span>
									<span class="red" style="vertical-align: top;">+1</span>
								</p>
								<p class="h50 line-h50">{{dataDetail.toStationName}}</p>
								<p>{{dataDetail.toDate}} {{dataDetail.week}}</p>
							</div>
						</div>
					</div>
					<div class="flex line-h24 borderTop padTB10" style="padding-left: 40px;"
						v-for="(item, index) in trRefundList" :key="index">
						<div class="bfb-w75">
							<div style="width: 100%; " class="flex">
								<div class="bfb-w50">
									<span class="td-bt">{{item.refundStatusDesc}}</span>
								</div>
								<div class="bfb-w50">
									<span class="label-span">退票合计：</span>
									<span style="color: #FFA558">￥{{item.cuRefundAmount}}</span>
								</div>
							</div>
							<div style="width: 100%;" class="flex">
								<div class="bfb-w50">
									<span>
										<span class="label-span">乘车人：</span>
										{{item.passengerName}}(身份证{{item.idCode}})
									</span>
								</div>
								<div class="bfb-w50">
									<span class="label-span">退票号：</span>
									<span>{{item.ticketCode}}</span>
								</div>
							</div>
						</div>

						<div class="bfb-w25 textRight">
							<span @click="queryRefundDetail(item.id)" class=" pointer padR24"
								style="color: #006BB9;">查看退票详情</span>
						</div>
					</div>
				</div>
			</div>
			<div class="marBtm10 border flex h80" v-if="trChangeList.length>0">
				<div class="left-main-width flex flexCenter bg borderRight">
					改签信息
				</div>
				<div style="width:996px;">
					<div style="width:996px;" v-for="(data, index) in trChangeList" :key="data.changeId">
						<div class="flex borderTop" :class="{borderTopNone:(index==0)}">
							<div style="width:80%;padding:40px 0;" class="flex">
								<div style="width: 33%;text-align: center;">
									<p>{{data.newFromTime}}</p>
									<p class="h50 line-h50">{{data.newFromStationName}}</p>
									<p>{{data.newFromDate}} {{data.newFromWeek}}</p>
								</div>
								<div style="width: 33%;">
									<div class="relative">
										<p class="absolute iconfont yj-icon-jiantou1 pCenter"></p>
										<p class="absolute ls-btm" style="text-align: center;width:100%;">
											{{data.newTravelTimeStr?data.newTravelTimeStr:''}}
										</p>
									</div>

								</div>
								<div style="width: 33%;text-align: center;">
									<p>{{data.newToTime}}</p>
									<p class="h50 line-h50">{{data.newToStationName}}</p>
									<p>{{data.newToDate}}
										({{data.newFromDate==data.newToDate?data.newFromWeek:data.newWeek}})
									</p>
								</div>
							</div>
						</div>
						<div class="flex line-h24 borderTop padTB10" style="padding-left: 40px;">
							<div class="bfb-w75">
								<div style="width: 100%; " class="flex">
									<div class="bfb-w50">
										<span class="td-bt">{{data.changeStatusName}}</span>
									</div>
									<div class="bfb-w50">
										<span class="label-span">改签合计：</span>
										<span style="color: #FFA558">￥{{data.newTicketPrice}}</span>
									</div>
								</div>
								<div style="width: 100%;" class="flex">
									<div class="bfb-w50">
										<span class="label-span">乘车人：</span>
										<span>{{data.passengerNames}}</span>
									</div>
								</div>
							</div>

							<div class="bfb-w25 textRight">
								<span @click="queryChangeDetail(data.changeId)" class=" pointer padR24"
									style="color: #006BB9;">查看改签详情</span>
								<el-button type="warning" class="marR24" v-if="data.showRefundButton==1"
									@click="goToTrRefund(data.changeId)">退 票</el-button>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="marBtm10 border flex h80">
				<div class="left-main-width text-center bg line-h78 borderRight">
					金额
				</div>
				<div style="width:40px;"></div>
				<div class="right-main-width flex line-h78">
					<div class="bfb-w25">
						<span class="label-span">票价：</span>
						<span>￥{{dataDetail.ticketPrice}}</span>
					</div>
					<div class="bfb-w25">
						<span class="label-span">服务费：</span>
						<span>￥{{dataDetail.sellServiceCharge}}</span>
					</div>
					<div class="bfb-w25">
						<span class="label-span">保险：</span>
						<span>￥{{(bxInfo.bxTotalPrice?bxInfo.bxTotalPrice:0)}}</span>
					</div>
					<div class="bfb-w25">
						<span class="label-span">总金额：</span>
						<span
							style="color:#ffa658;">￥{{dataDetail.ticketPrice+dataDetail.sellServiceCharge + (bxInfo.bxTotalPrice?bxInfo.bxTotalPrice:0)}}</span>
					</div>
				</div>
			</div>
			<div class="marBtm10 border flex h80">
				<div class="left-main-width text-center bg line-h78 borderRight">
					支付科目
				</div>
				<div style="width: 40px;"></div>
				<div class="right-main-width line-h78">
					<div>
						<span>{{dataDetail.paySubjectName}}</span>
					</div>
				</div>
			</div>
			<div class="marBtm10 border flex h80">
				<div class="left-main-width text-center bg line-h78 borderRight">
					联系人
				</div>
				<div style="width: 40px;"></div>
				<div class="right-main-width flex line-h78">
					<div class="bfb-w25">
						<span class="label-span">联系人：</span>
						<span>{{dataDetail.linkman}}</span>
					</div>
					<div class="bfb-w25">
						<span class="label-span">联系电话：</span>
						<span>{{dataDetail.linkMobile}}</span>
					</div>
					<div class="bfb-w25">
						<span class="label-span">联系邮箱：</span>
						<span>{{dataDetail.linkEmail}}</span>
					</div>
				</div>
			</div>
			<div class="marBtm20 border flex ">
				<div class="left-main-width text-center bg line-h78 borderRight">
					差旅信息
				</div>
				<div style="width: 40px;"></div>
				<div class="right-main-width flex flex-warp line-h78">
		
						<div class="bfb-w25">
							<span class="label-span">项目：</span>
							<span>{{dataDetail.projectName}}</span>
						</div>
						<div class="bfb-w25">
							<span class="label-span">出差单号：</span>
							<span>{{dataDetail.ccsqdNo}}</span>
						</div>
						<div></div>
						<div class="bfb-w50" v-if="dataDetail.violateitem && dataDetail.tripType == 1">
							<span class="label-span">违背事项：</span>
							<span>{{dataDetail.violateitem}}</span>
						</div>
						<div class="bfb-w50" v-if="dataDetail.reasonDesc && dataDetail.tripType == 1">
							<span class="label-span">违背原因：</span>
							<span>{{dataDetail.reasonDesc}}</span>
						</div>
				</div>
	
			</div>
			<yj-approval-cao-zuo  :appType="query.appType" :workItemId="query.workItemId"
				:orderType="query.orderType" :orderId="query.orderId" @closePage="resetDetail">
				<template slot="orderFooterSlot">
					<div class="textRight borderTop padTop20">
						<el-button class="padButton" @click="close">关 闭</el-button>
						<el-button class="padButton" v-show="dataDetail.isShowRefund==1 && dataDetail.orderStatus != 14" @click="toRefund(dataDetail.id)">退 票
						</el-button>
						<el-button class="padButton" v-show="dataDetail.isShowChange==1 && dataDetail.orderStatus != 14" @click="toChange(dataDetail.id)">改 签
						</el-button>
						<el-button class="padButton" v-show="dataDetail.isShowPay==1" @click="toPayment(dataDetail.id)">支 付</el-button>
						<el-button class="padButton" v-show="dataDetail.isShowSs==1" @click="sendApp(dataDetail.id)">送 审</el-button>
						<el-button class="padButton" v-show="dataDetail.isShowCancel==1" @click="trOrderCancel(dataDetail.id)">取 消</el-button>
						<!-- v-if='dataDetail.failureCode == "602020" || dataDetail.failureCode == "600108" || dataDetail.failureCode == "600109"' -->
						<el-button class="padButton" @click="checkLogin">12306核验</el-button>
						<el-button class="padButton" v-show="dataDetail.isShowCancelApprove==1" @click="orderCaoZuo(dataDetail,16)">撤 回</el-button>

					</div>

				</template>
			
			</yj-approval-cao-zuo>
		
		</el-card>
		<!-- <div v-if='dataDetail.failureCode == "602020" || dataDetail.failureCode == "600108" || dataDetail.failureCode == "600109"'> -->
			<train-login
				:showTrLogin="false" 
				:orderVisible="visible"
				:checkVisible="checkVisible"
				:cusTomerSendSmsProp="cusTomerSendSmsProp"
				:isQueryOrderAccount="isQueryOrderAccount"
				:orderInfo="orderInfo"
				@needSuccess="needSuccess" 
				@loginSuccess="loginSuccess" 
				@cancelLogin="cancelLogin"
				class="orderTrLogin"
			></train-login>
		<!-- </div>		 -->
	</div>


</template>

<script>
	import caozuoMixin from '@/mixin/models/caozuoMixin.js';
import TrainLogin from '@/views/tr/trComponents/trEdit/trainYdEdit/TrainLogin';
import RefundChangeRule from './trTGComponent/RefundChangeRule';
	export default {
		name: 'TrainDetail',
		mixins:[caozuoMixin.commonConfig],
		data() {
			return {
				dataDetail: {}, // 数据详情
				trRefundList: [], //退票信息集合
				trChangeList: [], //改签信息集合
				isPadTB: false, // 乘车人大于1人时，为true

				bxInfo: {}, // 保险信息

				timestamp: 0, // 每次进入详情页获取时间戳

				detail: {
					flowState: 3, // 当前人的审批状态 默认审批中  0未开始 1通过 2拒绝 3审批中
					currentLevel: 0, //需要审批人 当前层级
					appId: '', // 审批规则id
				},
				isSpStatus: false, // 当前审批人是否已审批过,		
				resetspjd: false, // 重置审批进度
				statusList: [{
						status: '1',
						name: '预订',
					},
					{
						status: '2',
						name: '支付',
					},
					{
						status: '3',
						name: '出票',
					},
				],
				currentStatus: 1, //状态
				styleColor: {
					color: '#DEAC56',
					padding: '24px 24px 0 0',
				},
				visible: false, //是否显示 12036 登录页面
				isQueryOrderAccount: true,// 是否查询订单关联的12306账号
				cusTomerSendSmsProp: true, // 走true-正常核验还是false-主动触发的核验
				checkVisible: false, // 是否显示核验页面
				orderInfo: { // 选择的订单信息
					// orderType: '',
					// orderId: '',
				} 
			}
		},
		computed: {
			// 天数
			days() {
			  return this.dataDetail.fromDate && this.dataDetail.toDate? this.$common.getDiffDate(this.dataDetail.fromDate, this.dataDetail.toDate, 0): 0;
			},
			userInfo() {
				return this.$common.getUserInfo() || {};
			},
			empId() {
				let empInfo = this.userInfo.empInfo || {};
				return empInfo.empId || '';
			},
			query() {
				return this.$route.query || {};
			},
			// 获取订单id
			orderId() {
				return this.query.orderId;
			},
			// 获取订单类型
			orderType() {
				return this.query.orderType;
			},
		},
		components: {
			'refund-change-rule': RefundChangeRule,
			'train-login':TrainLogin,
		},
		mounted() {
			// 获取订单详情
			this.getTrOrderDetail();
			// 获取退票订单信息
			this.getFindTrRefundOrder();
			// 获取改签票订单信息
			this.getFindTrChangeOrderByOrderId();
			// 获取保险信息
			this.getBxOrder();
		},
		methods: {
			// 获取火车详情页信息
			getTrOrderDetail() {
				let obj = {
					id: this.orderId,
				};
				this.$conn.getConn('trBook.trOrderDetail', {
					jsfile: false
				})(obj, (res) => {
					let data = res.data || {};
					this.timestamp = res.timestamp || 0;
					this.dataDetail = data;
					this.gressBiao(data)


					//1.待审批2.审批中3.订座中4.待支付5.出票中6.已出票
					//7.已取消8.审批拒绝9.取消中15.抢票中16.抢票失败 ,
					this.detail.appId = this.dataDetail.appId;
					if (data.psgList && data.psgList.length > 1) {
						this.isPadTB = true
					} else {
						this.isPadTB = false
					}
					
				}, (err) => {
					this.$alert(err.tips, '温馨提示', {
						type: 'error'
					})
				})
			},
			// 设置进度条 
			gressBiao(data) {
				//订单状态:1.待审批2.审批中3.订座中4.待支付5.出票中6.已出票7.已取消8.审批拒绝9.取消中15.抢票中16.抢票失败,17.订座失败 ,
				// grad 参数1 表示抢票， 0与空 正常票
				let grad = 0;
				grad = data.grad || 0;
				if (grad == 0) {
					if (data.vipStatus != 4) {
						this.statusList = [{
								status: '1',
								name: '预订',
							},
							{
								status: '2',
								name: '送审',
							},
							{
								status: '3',
								name: '支付',
							},
							{
								status: '4',
								name: '出票',
							},
						];
						if (data.vipStatus == '1') {
							this.currentStatus = 2;
						} else if (data.orderStatus == 1 || data.vipStatus == 0) {
							this.currentStatus = 1;
						} else if (data.orderStatus == 2 || data.orderStatus == 3) {
							this.currentStatus = 2;
						} else if (data.orderStatus == 4) {
							this.currentStatus = 2;
						} else if (data.orderStatus == 5) {
							this.currentStatus = 3;
						} else if (data.orderStatus == 6) {
							this.currentStatus = 4;
						} else if (data.orderStatus == 7 || data.orderStatus == 8 || data.orderStatus == 9) {
							this.currentStatus = 0;
						} else if (data.orderStatus == 9 && data.vipStatus == 3) {
							this.currentStatus = 2;
						}

					} else {
						this.statusList = [{
								status: '1',
								name: '预订',
							},
							{
								status: '2',
								name: '支付',
							},
							{
								status: '3',
								name: '出票',
							},
						];
						if(data.payStatus==0){
							if(data.orderStatus == 6 || data.orderStatus == 17){
								this.currentStatus = 0;
							}
							
						}else{
							if (data.orderStatus == 0 || data.orderStatus == 3) {
								this.currentStatus = 1;
							} else if (data.orderStatus == 4) {
								this.currentStatus = 1;
							} else if (data.orderStatus == 5) {
								this.currentStatus = 2;
							} else if (data.orderStatus == 6) {
								this.currentStatus = 3;
							} else if (data.orderStatus == 7 || data.orderStatus == 8) {
								this.currentStatus = 0;
							}
						}
						
					}
				} else {
					if (data.vipStatus != 4) {
						this.statusList = [{
								status: '1',
								name: '提交订单',
							},
							{
								status: '2',
								name: '送审',
							},
							{
								status: '3',
								name: '支付',
							},
							{
								status: '4',
								name: '抢票中',
							},
							{
								status: '5',
								name: '出票',
							},
						];
						if (data.orderStatus == '4' || data.orderStatus == '2') {
							this.currentStatus = 2;
						} else if (data.orderStatus == '8') {
							this.currentStatus = 0;
						} else if (data.orderStatus == '15') {
							this.currentStatus = 4;
						} else if (data.orderStatus == '5') {
							this.currentStatus = 4;
						} else if (data.orderStatus == '6') {
							this.currentStatus = 5;
						} else if (data.orderStatus == '7' || data.orderStatus == '1') {
							this.currentStatus = 0;
						}

					} else {
						
						this.statusList = [{
								status: '1',
								name: '提交订单',
							},
							{
								status: '2',
								name: '支付',
							},
							{
								status: '3',
								name: '抢票中',
							},
							{
								status: '4',
								name: '出票',
							},
						];
						if (data.orderStatus == '4') {
							this.currentStatus = 1;
						} else if (data.orderStatus == '5') {
							this.currentStatus = 2;
						} else if (data.orderStatus == '15') {
							this.currentStatus = 3;
						} else if (data.orderStatus == '6') {
							this.currentStatus = 4;
						} else if (data.orderStatus == '7' || data.orderStatus == '16') {
							this.currentStatus = 0;
						}
					}

				}


			},
			// 获取退票信息集合
			getFindTrRefundOrder() {
				let obj = {
					orderId: this.orderId
				};
				this.$conn.getConn('trRefund.findTrRefundOrderByIds', {
					jsfile: false
				})(obj).then((res) => {
					let data = res.data || [];
					this.trRefundList = data;
				}).catch((err) => {
					this.$alert(err.tips, '温馨提示', {
						type: 'error'
					})
				})
			},
			// 获取改签票信息集合
			getFindTrChangeOrderByOrderId() {
				let obj = {
					orderId: this.orderId
				};
				this.$conn.getConn('trChange.findTrChangeOrderByOrderId', {
					jsfile: false
				})(obj).then((res) => {
					let data = res.data || [];
					this.trChangeList = data;
				}).catch((err) => {
					this.$alert(err.tips, '温馨提示', {
						type: 'error'
					})
				})
			},
			// 获取保险
			getBxOrder() {
				let obj = {};
				obj = {
					glddId: this.orderId
				}
				this.$conn.getConn('insurance.getBxOrder', {
					jsfile: false
				})(obj).then((res) => {
					let data = res.data || {};
					this.bxInfo = data;
				}).catch((err) => {
					this.$alert(err.tips, '温馨提示', {
						type: 'error'
					})
				})
			},
			// 查看退票详情
			queryRefundDetail(id) {
				let routeUrl = this.$router.resolve({
					path: "trainRefundDetail",
					query: {
						orderId: id,
						orderType: 11010,
					}
				});
				window.open(routeUrl.href, '_blank')
			},
			// 查看改签详情
			queryChangeDetail(changeId) {
				let routeUrl = this.$router.resolve({
					path: "trainChangeDetail",
					query: {
						orderId: changeId,
						orderType: 11011,
					}
				});
				window.open(routeUrl.href, '_blank')
			},
			// 获取单号字体颜色
			getTextColor() {
				if (JSON.stringify(this.dataDetail) != "{}") {
					let styleObj = {}
					if (this.dataDetail.orderStatus == 6 || this.dataDetail.orderStatus == 7) {
						styleObj = {
							'color': '#FFA558',
						}
					} else if (this.dataDetail.orderStatus == 4 || this.dataDetail.orderStatus == 9 || this.dataDetail
						.orderStatus == 15) {
						styleObj = {
							'color': '#99ccff',
						}
					} else if (this.dataDetail.orderStatus == 5) {
						styleObj = {
							'color': '#0066ff',
						}
					} else if (this.dataDetail.orderStatus == 8 || this.dataDetail.orderStatus == 16 || this.dataDetail
						.orderStatus == 17) {
						styleObj = {
							'color': 'red',
						}
					}

					return styleObj
				}
			},
			// 去退票
			toRefund(orderId) {
				if(this.dataDetail.productType == 2) {
					this.$alert('当前订单暂无法提交，请联系空港嘉华客服热线：010-64581922协助处理！', '温馨提示', {
					        confirmButtonText: '确定',
					        callback: action => {
					    }
					})
					return false
				}
				let routeUrl = this.$router.resolve({
					path: "/yjEdit/trainRefund",
					query: {
						orderId: orderId,
						applyType: ''
					}
				});
				window.open(routeUrl.href, '_blank')
			},
			// 去改签
			toChange(orderId) {
				if(this.dataDetail.productType == 2) {
					this.$alert('当前订单暂无法提交，请联系空港嘉华客服热线：010-64581922协助处理！', '温馨提示', {
					        confirmButtonText: '确定',
					        callback: action => {
					    }
					})
					return false
				}
				let routeUrl = this.$router.resolve({
					path: "/yjEdit/trainChange",
					query: {
						'orderId': orderId
					}
				});
				window.open(routeUrl.href, '_blank')
			},
			// 火车票支付时间
			overTime() {
				let diffTime = ""; //对比分钟
				let time = 0;
				if (this.dataDetail.latePaymentTime) {
					// 计算对比最晚支付时间
					diffTime = this.$common.getDiffMinute(this.dataDetail.bookDate, this.dataDetail.latePaymentTime);
					let sTime = diffTime.toFixed(2).toString().split(".");
					if(sTime.length==2){
						let second = sTime[1]-0>=10?((sTime[1]-0)*60/100).toFixed(0):((sTime[1]-0)*60/10).toFixed(0)
						time = sTime[0]+'.'+second;
					}else{
						time = Math.ceil(diffTime)
					}
				}
				return time;
			},
			// 去支付
			toPayment(orderId) {
				let time = this.overTime();
				let routeUrl = this.$router.resolve({
					path: "/yjDetail/payment",
					query: {
						orderId: orderId,
						orderType: this.orderType || 11009,
						timeOut: time,
					}
				});
				window.open(routeUrl.href, '_blank')
			},
			// 取消
			trOrderCancel(orderId) {
				let queryObj = {
					orderId: orderId,
				}
				this.$confirm('确定要取消当前火车票订单!', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					closeOnClickModal: false,
					type: 'warning'
				}).then(() => {
					this.$conn.getConn('trBook.trOrderCancel')(queryObj, res => {
						if (res.httpStatus == 200 && res.errCode == 1) {
							this.getTrOrderDetail()
							this.$message({
								type: 'success',
								message: '取消成功',
							});
						}
					}, err => {
						this.$alert(err.tips, '温馨提示', {
							type: 'error'
						})
					})

				}).catch(() => {
					this.$message({
						type: 'info',
						message: '取消关闭',
					});
				});

			},
			// 操作  16 撤回
			orderCaoZuo(info, type) {
				if (type == 16) {
					this.goToCancelApprove({
						orderId:this.orderId,
						orderType: 11009,
					}, (res) => {

						this.getTrOrderDetail();
					}, (err) => {

					})
				}
			},
			// 审批进度
			showProgress(info, currentSpjd, status) {
				// this.spjd = info.spjd; //当前审批进度（1/2）
				this.resetspjd = false;
				this.detail.currentLevel = currentSpjd; //当前到谁审批
				this.detail.flowState = status; // 当前审人状态
				this.getCurrentSprLevel()
			},
			// 获取当前审批人层级列表
			getCurrentSprLevel() {
				let params = {
					spgzId: this.detail.appId
				}
				this.$conn.getConn('commu.appRulesDetail')(params, (res) => {
					let data = res.data || {};
					let applyList = data.gznr || [];
					this.getGznrList(applyList)
				}, (err) => {

				})
			},
			// 获取审批人列表
			getGznrList(list) {
				if (list.length > 0) {
					// 遍历审批人
					for (var i = 0; i < list.length; i++) {
						// 需要审批的当前层级 与 列表中层级相等表示 轮到当前人审批
						if (list[i].level == this.detail.currentLevel) {
							// 判断登录人empid 与 层级中id 是否相等， 相等则可以审批
							for (var j = 0; j < list[i].empList.length; j++) {
								if (list[i].empList[j].empId == this.empId && this.detail.flowState == 3) {
									this.isSpStatus = true;
								} else {
									this.isSpStatus = false;
								}
							}
						}
					}
				}

			},
			// 送审
			sendApp(id) {
				// if(!this.dataDetail.appId){
				// 	this.$message.warning('审批规则id不能为空')
				// 	return
				// }

				var queryParams = {};
				queryParams = {
					orderId: id,
					orderType: 11009,
					spgzId: this.dataDetail.appId,
				};
				this.$confirm('您确定要送审吗?', '', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					this.$conn.getConn('commu.sendApp')(queryParams, (res) => {
						this.$message({
							type: 'success',
							message: '送审成功'
						});
						this.getTrOrderDetail();
					}, (err) => {
						this.$message({
							type: 'error',
							message: err.tips
						});
					})
				}).catch(() => {
					this.$message({
						type: 'info',
						message: '关闭取消'
					});
				});
			},
			// 去退票填写页
			goToTrRefund(changeId) {
				let routeUrl = this.$router.resolve({
					path: "/yjEdit/trainRefund",
					query: {
						orderId: changeId,
						applyType: 1
					}
				});

				window.open(routeUrl.href, '_blank')
			},

			// 关闭本页面
			close() {
				window.close();
			},
			// 重置页面
			resetDetail() {
				this.$router.replace({
					path: this.$dict.detailConfig(11009),
					query: {
						...this.$route.query,
						appType:2  // 已审批     0 订单详情 1.待审批 2.已审批 3. 审批过期 
	
					}
				})
				this.getTrOrderDetail()
				this.resetspjd = true;
			},
			// 12306核验
			checkLogin(){
				this.orderInfo = {
					orderId: this.dataDetail.id,
					orderType: 11009,
				};				
				setTimeout(()=>{
					if (this.dataDetail.failureCode == '602020' || this.dataDetail.failureCode == '600109') {
						this.checkVisible = true;
						this.cusTomerSendSmsProp = this.dataDetail.failureCode == '602020'? false: true;
					} else {
						this.visible = true;
					}
				},200)	
			},
			//登录成功
			loginSuccess(){
				this.visible = false;
				this.checkVisible = false;
			},
			// 核验成功 重置订单详情
			needSuccess(){
				this.getTrOrderDetail();
			},
			// 取消登录
			cancelLogin(){
				this.loginSuccess();
			}
		}
	}
</script>

<style scoped lang="scss">
	/* bfb-w  表示百分比宽度*/
	.td-main {
		margin: 0 auto;
		width: 1200px;
		.orderTrLogin{
			/deep/ .el-input__inner{
				height: 35px;
				background: #FFFFFF;
				border: 1px solid #CCCCCC!important;
				border-radius: 0;
			}
		}
		.sp-main {
			margin-bottom: 10px;
			padding: 35px 30px;

			.setqd {
				width: 80px;
				height: 80px;
				padding: 8px;

				.set-item {
					width: 59px;
					height: 59px;
					font-size: 12px;
					padding: 0;
					text-align: center;
					line-height: 59px;
				}
			}

			.bordersp1 {
				border: 2px solid #424242;
				color: #424242;
				border-radius: 50%;
			}

			.bordersp2 {
				border: 2px solid #006BB9;
				color: #006BB9;
				border-radius: 50%;
			}

			.bordersp3 {
				border: 2px solid #FF0000;
				color: #FF0000;
				border-radius: 50%;
			}

			.bordersp4 {
				border: 2px solid #f5a337;
				color: #f5a337;
				border-radius: 50%;
			}
		}

		.flex {
			display: flex;
		}
		.flex-warp{
			flex-wrap: wrap;
		}

		/deep/ .el-dialog {
			width: 700px;
		}

		.bold {
			font-weight: bold;
		}

		.textline p {
			line-height: 24px;
		}

		.bfb-w25 {
			width: 25%;
		}

		.bfb-w50 {
			width: 50%;
		}

		.bfb-w75 {
			width: 75%;
		}

		.between {
			justify-content: space-between;
		}

		.relative {
			position: relative;
		}

		.absolute {
			position: absolute;
		}

		.pCenter {
			left: 50%;
			top: 25px;
			transform: translate(-50%, 0);
		}

		.font16 {
			font-size: 16px;
		}

		.ls-top {
			top: 35px;
			left: 50%;
			transform: translate(-50%, 0);
		}

		.ls-btm {
			top: 45px;
		}

		.marBtm10 {
			margin-bottom: 10px;
		}

		.marBtm20 {
			margin-bottom: 20px;
		}

		.marTop20 {
			margin-top: 20px;
		}

		.marR24 {
			margin-right: 24px;
		}

		.padL10 {
			padding-left: 10px;
		}

		.padTB10 {
			padding: 10px 0;
		}

		.padTop20 {
			padding-top: 20px;
		}

		.padL40 {
			margin-left: 40px;
		}

		.textLeft {
			text-align: left;
		}

		.textRight {
			text-align: right;
		}

		.padButton {
			padding: 10px 30px;
			font-size: 15px;
		}

		.left-main-width {
			width: 160px;
		}

		.right-main-width {
			width: 960px;
		}

		.border {
			border: 1px solid #E6E6E6;
			box-sizing: border-box;
		}

		.borderRight {
			border-right: 1px solid #E6E6E6;
		}

		.borderTop {
			border-top: 1px solid #E6E6E6;
		}

		.borderTopNone {
			border-top: none;
		}

		.h80 {
			min-height: 80px;
		}

		.h50 {
			height: 50px;
		}

		.line-h50 {
			line-height: 50px;
		}

		.justCenter {
			justify-content: center;
		}

		.flexCenter {
			align-items: center;
			justify-content: center;
		}

		.alignItem {
			align-items: center;
		}

		.line-h78 {
			line-height: 78px;
		}

		.line-h40 {
			line-height: 40px;
		}

		.line-h24 {
			line-height: 24px;
		}

		.padTB30 {
			padding: 30px 0px;
		}

		.padR24 {
			padding-right: 24px;
		}

		.colorBlue {
			color: blue;
		}

		.bg {
			background: #FAFAFA;
		}

		.text-center {
			// height: 100%;
			text-align: center;
		}

		.w100 {
			width: 100px;
		}

		.bgC {
			background-color: #CCCCCC;
		}

		.h2 {
			height: 2px;
		}

		.tgqgz {
			color: #DEAC56;
			text-align: right;
			padding: 24px 24px 0 0;
		}

		.pointer {
			cursor: pointer;
		}

		.td-bt {
			height: 24px;
			background: #006BB9;
			color: #fff;
			padding: 0 8px;
			display: inline-block;
			border-radius: 4px;
		}
		.orange {
			color: rgb(255, 165, 88);
		} 
		.font12 {
			font-size: 12px;
		} 
		.align_top {
			vertical-align: top;
		}
		.label-span {
			color: #909399;
		}
	}
</style>
