<template>
	<div class="trd-main">
		<div class="main" v-if="JSON.stringify(trianRefundDetail)!='{}'">
				<yj-progress-edit :statusList="statusList" :currentStatus="currentStatus"></yj-progress-edit>
			<type-order :dataDetail="trianRefundDetail" :marginTop="'0'" :title="'退票订单'" :orderType="11010"></type-order>
			<order-tg-info :dataDetail="trianRefundDetail" :marginTop="'20px'" :title="'退票信息'" :orderType="11010"></order-tg-info>
			<passenger-info :dataDetail="trianRefundDetail" :marginTop="'20px'" :title="'乘车人信息'" :orderType="11010"></passenger-info>
			<yj-contact-info :marginTop="'20px'" ></yj-contact-info>
			<el-card v-if="trianRefundDetail.appId && trianRefundDetail.vipStatus==0" class="cardText">
				<div class="title">审批规则详情</div>
				<div class="trd-jd">
					<yj-approval-rule-detail :detail="detail" :appId="detail.appId" @gznrList="getGznrList"></yj-approval-rule-detail>	
				</div>	
		
			</el-card> 
			<el-card v-if="trianRefundDetail.appId && trianRefundDetail.vipStatus>0" class="cardText">
				<div class="title">审批进度<span style="font-size: 15px;color: #808080;padding-left: 20px;">({{spjd}})</span></div>
				<div class="trd-jd">
					<yj-approval-progress :orderId="query.orderId" :orderType="query.orderType" :resetspjd="resetspjd" @showProgress="showProgress"></yj-approval-progress>
				</div>			
			</el-card>
		
			

			<yj-approval-cao-zuo  
				:appType="query.appType" 
				:workItemId="query.workItemId"
				:orderType="query.orderType" 
				:orderId="query.orderId" 
				@closePage="resetDetail">
					<template slot="orderFooterSlot">
						<div class="btn">
							<el-button class="button"  v-if="trianRefundDetail.showSendApp==1" @click="sendApp">送 审</el-button>
							<el-button class="button" v-if="trianRefundDetail.showCancel==1" @click="getCaoZuo(1,trianRefundDetail)">取 消</el-button>
							<!-- v-if='trianRefundDetail.failureCode == "602020" || trianRefundDetail.failureCode == "600108" || trianRefundDetail.failureCode == "600109"' -->
							<el-button class="button" @click="checkLogin">12306核验</el-button>
							<el-button class="button" v-show="trianRefundDetail.isShowCancelApprove==1" @click="orderCaoZuo(trianRefundDetail,16)">撤 回</el-button>
						</div>
					</template>	
			</yj-approval-cao-zuo>

		</div>
		<!-- <div v-if='trianRefundDetail.failureCode == "602020" || trianRefundDetail.failureCode == "600108" || trianRefundDetail.failureCode == "600109"'> -->
			<train-login
				:showTrLogin="false" 
				:orderVisible="visible"
				:checkVisible="checkVisible"
				:cusTomerSendSmsProp="cusTomerSendSmsProp"
				:isQueryOrderAccount="isQueryOrderAccount"
				:orderInfo="orderInfo"
				@needSuccess="needSuccess" 
				@loginSuccess="loginSuccess" 
				@cancelLogin="cancelLogin"
				class="orderTrLogin"
			></train-login>
		<!-- </div>	 -->
	</div>
</template>

<script>
	import TrainLogin from '@/views/tr/trComponents/trEdit/trainYdEdit/TrainLogin';
import OrderTGInfo from './trTGComponent/OrderTGInfo';
import PassengerInfo from './trTGComponent/PassengerInfo';
import TypeOrder from './trTGComponent/TypeOrder';

	import caozuoMixin from '@/mixin/models/caozuoMixin.js';
	export default{
		name: 'TrainRefundDetail',
		mixins:[caozuoMixin.commonConfig],
		data() {
			return {
				trianRefundDetail: {},
				isSpStatus: false, //审批人是否可以审批
				isGetApplylevel: false, // 是否获取审批人层级
				spjd: '', //审批进度
				showSend: false,
				// query:{}, // 接受路由传参
				detail: {
					flowState: 3, // 当前人的审批状态 默认审批中  0未开始 1通过 2拒绝 3审批中
					currentLevel: 0,  //需要审批人 当前层级
					appId:'', // 审批规则id
				},
				resetspjd: false,
				empId:'',
				statusList:[
					{
						status: '1',
						name: '申请已提交',
					},
					{
						status: '2',
						name: '送审',
					},
					{
						status: '3',
						name: '办理退票',
					},
					{
						status: '4',
						name: '退票完成',
					},
					{
						status: '5',
						name: '已退款',
					},
				],
				currentStatus:0, //状态
				visible: false, //是否显示 12036 登录页面
				isQueryOrderAccount: true,// 是否查询订单关联的12306账号
				cusTomerSendSmsProp: true, // 走true-正常核验还是false-主动触发的核验
				checkVisible: false, // 是否显示核验页面
				orderInfo: { // 选择的订单信息
					// orderType: '',
					// orderId: '',
				} 
			}
		},
		components:{
			'type-order': TypeOrder,
			'order-tg-info': OrderTGInfo,
			'passenger-info': PassengerInfo,
			'train-login': TrainLogin,
		},
		computed:{
			query(){
				return this.$route.query || {}
			}
		},
		mounted() {
			let uesrInfo = this.$common.getUserInfo() || {};
			this.empId = uesrInfo.empInfo.empId;
			// this.query = this.$route.query || {};
			this.getTrainRefundDetail();
		},
		methods: {
			// 获取火车票退票详情
			// 详情中 orderId 是订单orderId  退票与改签详情中用 id ==refundId
			getTrainRefundDetail(){
				let obj = {
					id: this.query.orderId,
					
				}
				this.$conn.getConn('trRefund.trRefundDetail',{jsfile:false})(obj).then((res)=>{
					let data = res.data || {}
					this.trianRefundDetail = data;
					this.gressBiao(data);
					this.showSend = (data.showSendApp==1);
					this.detail.appId = data.appId;
				}).catch((err)=>{
					this.$alert(err.tips,'温馨提示',{
						type: 'error' 
					})
				})
				
			},
			// 头部进度表处理
			gressBiao(data){
				//refundStatus 1.待送审2.审批中3.办理中4.已办理6.已取消7.已退款 ,
				if(data.vipStatus!=4){
					this.statusList = [
						{status: '1',name: '申请已提交',},
						{status: '2',name: '送审',},
						{status: '3',name: '办理退票',},
						{status: '4',name: '退票完成',},
						{status: '5',name: '已退款',},
					];
					
					if( data.vipStatus=='1'){
						this.currentStatus = 2;
					}else if(data.refundStatus == '1'|| data.vipStatus=='0'){
						this.currentStatus = 1;
					}else if(data.refundStatus == 2){
						this.currentStatus = 2;
					}else if(data.refundStatus == '3'){
						this.currentStatus = 3;
					}else if(data.refundStatus == '4'){
						this.currentStatus = 4;
					}else if(data.refundStatus == '6' || data.refundStatus == '5'){
						this.currentStatus = 0;
					}else if(data.refundStatus == '7'){
						this.currentStatus = 5;
					}
				}else{
					
					this.statusList = [
						{status: '1',name: '申请已提交',},				
						{status: '2',name: '办理退票',},
						{status: '3',name: '退票完成',},
						{status: '4',name: '已退款',},
					];
					if(data.refundStatus == "1"){
						this.currentStatus = 0;
					}else if(data.refundStatus == "3"){
						this.currentStatus = 2;
					}else if(data.refundStatus == "4"){
						this.currentStatus = 3;
					}else if(data.refundStatus == "6" || data.refundStatus == "5"){
						this.currentStatus = 0;
					}else if(data.refundStatus == "7"){
						this.currentStatus = 4;
					}
				}
			},
			// 审批进度显示
			showProgress(info,currentSpjd,status){
				this.spjd = info.spjd; //当前审批进度（1/2）
				this.resetspjd = false;
				this.detail.currentLevel = currentSpjd; //当前到谁审批
				this.detail.flowState = status;  // 当前审人状态
				this.getCurrentSprLevel()
			},
			//操作
			// getCaoZuo(type,detail){				
			// 	this.cancelRefund(detail.id)
			// },
			// 操作  16 撤回   1 取消
			
			orderCaoZuo(detail, type) {
				if(type == 1){
					this.cancelRefund(detail.id)
				}else if (type == 16) {
					this.goToCancelApprove({
						orderId: this.query.orderId,
						orderType: 11010,
					}, (res) => {

						this.getTrainRefundDetail();
					}, (err) => {

					})
				}
			},
			// 取消退票
			cancelRefund(id){
				let params = {
					id: id
				}
				var url = "trRefund.cancelTrRefund";
				this.$confirm('确定取消退票订单!', '提示', {
				        confirmButtonText: '确定',
				        cancelButtonText: '取消',
						closeOnClickModal: false,
				        type: 'warning'
				    }).then(() => {
						this.$conn.getConn(url)(params,(res)=>{
							this.$alert('取消成功！','提示',{ 
								type:'success',
								confirmButtonText: '确定',
								callback: action => {
									this.getTrainRefundDetail();
								}
							})
							
						},(err)=>{
							this.$alert(err.tips,'提示',{
								type:'error',
							})
						})
				        
				    }).catch(() => {
				        this.$message({
				        	type: 'info',
				        	message: '取消关闭',
				        });     
				    });  
				
			},
			// 获取当前审批人层级列表
			getCurrentSprLevel(){
				let params = {
					spgzId: this.detail.appId
				}
				this.$conn.getConn('commu.appRulesDetail')(params,(res)=>{
					let data =  res.data || {};
					let applyList = data.gznr || [];
					this.getGznrList(applyList)
				},(err)=>{
					
				})
			},
			// 获取审批人列表
			getGznrList(list){
				if(list.length>0){
					// 遍历审批人
					for(var i=0; i<list.length; i++){
						// 需要审批的当前层级 与 列表中层级相等表示 轮到当前人审批
						if(list[i].level == this.detail.currentLevel){
							// 判断登录人empid 与 层级中id 是否相等， 相等则可以审批
							for(var j=0; j<list[i].empList.length;j++){
								if(list[i].empList[j].empId == this.empId && this.detail.flowState==3){
									this.isSpStatus = true;
								}else{
									this.isSpStatus = false;
								}
							}
						}
					}
				}
				
			},
			// 送审
			sendApp(){
				var queryParams = {};
					queryParams = {
						orderId: this.query.orderId,
						orderType: 11010,
						spgzId: this.detail.appId,
					};
				this.$confirm('您确定要送审吗?', '', {
				    confirmButtonText: '确定',
				    cancelButtonText: '取消',
				    type: 'warning'
				    }).then(() => {
				    	this.$conn.getConn('commu.sendApp')(queryParams,(res)=>{
							this.$message({
							    type: 'success',
							    message: '送审成功'
							}); 
							this.getTrainRefundDetail();
						},(err)=>{
							this.$message({
							    type: 'error',
							    message: err.tips
							});   
						})
				    }).catch(() => {
				      this.$message({
				        type: 'info',
				        message: '关闭取消'
				    });              
				});
			},
			// 重置详情信息
			resetDetail(){
			
				
				this.$router.replace({
					path: this.$dict.detailConfig(11010),
					query: {
						...this.$route.query,
						appType:2  // 已审批     0 订单详情 1.待审批 2.已审批 3. 审批过期 
	
					}
				})
				window.opener.location.reload();
				this.getTrainRefundDetail();
				this.resetspjd = true;
				return 
			
			},
			// 12306核验
			checkLogin(){
				this.orderInfo = {
					orderId: this.trianRefundDetail.id,
					orderType: 11010,
				};				
				setTimeout(()=>{
					if (this.trianRefundDetail.failureCode == '602020' || this.trianRefundDetail.failureCode == '600109') {
						this.checkVisible = true;
						this.cusTomerSendSmsProp = this.trianRefundDetail.failureCode == '602020'? false: true;
					} else {
						this.visible = true;
					}
				},200)	
			},
			//登录成功
			loginSuccess(){
				this.visible = false;
				this.checkVisible = false;
			},
			// 核验成功 重置订单详情
			needSuccess(){
				this.resetDetail();
			},
			// 取消登录
			cancelLogin(){
				this.loginSuccess();
			}
		}
	}
</script>

<style scoped lang="scss">
	.el-card.is-always-shadow{
		box-shadow: 1px 2px 2px 0px rgba(13, 2, 0, 0.02);
		border-radius: 5px ;
	}
	.trd-main{
		background-color: #EEF1F6;
		font-family: '微软雅黑';
		.main{
			width: 1200px;
			margin: 0 auto;
			padding-top: 20px;
			.textRight{
				text-align: right;
			}
			.marTB20{
				margin: 20px 0;
			}
			.close{
				padding: 10px 30px;
				font-size: 15px;
				
			}
			.title{
				font-size: 24px;
				font-weight: 600;
				line-height: 22px;
				text-align: left;    
			}
			
			.cardText{
				margin-top: 20px;
				/deep/ .el-card__body{
					padding: 30px;
				}
				.title{
					font-size: 24px;
					font-weight: 600;
					color: #424242;
					line-height: 24px;
					text-align: left;
				}
				.trd-jd{
					margin-top: 30px;
					border: 1px solid #e6e6e6;
					width: 100%;
					padding: 20px;
				}
				.spmain{
					margin-top: 38px;
				}
				.spselcet{
					text-align: left;
					padding-bottom: 10px;
				}
			}
			.btn{
				padding: 30px 0;
				.button{
					width: 150px;
					height: 50px;
					font-size: 15px;
					
				}
			}
			
		}
		
		
	}
	
</style>
