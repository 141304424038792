<template>
	<div>
		<yj-nav-detail></yj-nav-detail>		
			
		
		<router-view></router-view>
	</div>
</template>

<script>
	export default{
		name:'YjDetail',
		data(){
			return{
				
			}
		}
	}
</script>

<style>
</style>
